import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import SingleImage from '../components/layout/single-image';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2023" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2023" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`Túlzás nélkül állíthatjuk, hogy a 2023-as év a NOÉ Állatotthon több mint 30 éves fennállásának legtragikusabb éve. 2023. januárban betört az állataink közé a magas patogenitású madárinfluenza vírus. A fertőzés miatt nagyjából 300 madarunkat vesztettük el - volt akit a betegség ölt meg, és 180 madarunkat a szigorú járványügyi előírások alapján kiirtottak -, valamint hetekig hatósági zárlat alatt volt az egész menhely. Több száz olyan védencünket vesztettünk el, akiket mind mi mentettünk, neveltünk fel, gyógyítottunk meg. Akiket név szerint ismertünk, sokukat évek óta gondoztunk. Borzasztó hetek voltak, volt olyan pillanat amikor azt éreztük, hogy vége, ebből sem fizikailag, sem lelkileg nem tudunk felállni.  De végül felemeltük a fejünket, egymást támogatva megerősítettük a lelkünket és a rengeteg bajban levő, rászoruló állatért megyünk tovább.`}</p>
    <p>{`A mellettünk dúló háború, a gazdasági nehézségek, az őrületesen elszállt állatorvosi, gyógyszer, táp, üzemanyag, rezsi árak komoly kihívás elé állít minket is. Hatalmas a felelősségünk, hiszen nem csak a nagyjából 1000 védencünkért tartozunk felelősséggel, de a nálunk dolgozó gondozókért és családjaikért. Ezért átnéztük az összes munkafolyamatunkat, minden kiadásunkat és nagyon szoros költségvetés mentén zajlik jelenleg a mentési munka. Lavírozunk, hogy minél több életveszélyben lévő, beteg állatot meg tudjunk menteni, ugyanakkor a már gondozásunkban lévő védencek is mindent megkaphassanak.  Nagyon nehéz megtalálni az egyensúlyt akkor amikor több tíz segélykérés fut be naponta.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Ráadásul sajnos drasztikusan visszaesett az örökbefogadások száma és sokszorosára nőtt a visszahozott állatok mennyisége is. Teljesen új kihívásokkal kell szembe néznünk napjainkban, mint eddig bármikor. Sajnos még a kölykök örökbefogadása is lecsökkent. A folyamatosan csúcsra járatot baba-mama karanténunkba hosszú hetekre beragadnak a babák. Pedig ez a hely a fertőzések kiküszöbölésére jött létre, de egyáltalán nem alkalmas a kölykök szocializációs folyamatát kiszolgálni.`}</p>
    <p>{`Hirtelen azt vettük észre, hogy tele vagyunk félős, a világ dolgait nem ismerő, nem elég szociális kölykökkel, növendékekkel. Miközben budapesti menhely lévén, tőlünk elsősorban városi kutyának fogadnak örökbe a gazdijaink. A városi kutyaság minden szépségével, menő dolgaival (kutyabarát kávézók, éttermek, futtatók, strandok, kutyasulik, stb.) és bizony minden kihívásával együtt. A városi élethez egy kutyának extra jól szocializáltnak kell lenni. Így nem tudtunk mást tenni az előre menekülést választottuk. Profi kutyatrénerek segítségével képzést kaptak a gondozóink, önkénteseink egy más minőségű kölyök szocializációra. Illetve a nálunk az 50 órás kötelező közösségi szolgálatukat teljesítő diákoknak beindítottuk a Kölyökidő programunkat, melynek keretében elsősorban a kölyök kutyákkal foglalkozhatnak. Elképesztően jó eredményeink vannak már rövid távon is.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2023-ban is országos szinten zajlott az állatok mentése. Szorosan együttműködve több állatvédő szervezettel vidéki gyepmesteri telepekről, szegregátumok nyomortanyáiról, az vagy éppen az utak széléről mentettünk állatokat.  Természetesen tovább zajlott a küzdelem a szaporító telepek ellen, a kutyaviadalok áldozataiért is. Sok esetben vettünk át vidéki nehéz helyzetben lévő állatvédőktől, menhelyektől állatokat. Ezzel esélyt adtunk a gyorsabb gazdásodására és helyet biztosítottunk újabb valóban nagyon nagy bajban lévő állatok megmentésének.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(27, 30)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Ami nem változik, sőt sajnos romló tendenciát mutat, hogy továbbra is tarol a szívférgesség és a legkülönbözőbb paraziták jelenléte. A bekerülő kutyák túlnyomó része érintett egyikben, vagy másikban, sok esetben többen is.`}</p>
    <p>{`A modern, fertőtleníthető baba-mama karanténnak és a szigorú karanténozási és fertőtlenítési szabályainknak hála a kutyáknál a parvo megbetegedések száma csökkent. A cicáknál viszont soha nem látott mennyiségben találkozunk a macska parvoval (panleukopenia). A betegség leküzdéséhez - akár kölyökkutyáról, vagy cicáról beszélünk - szinte mindig intenzív kórházi ellátásra van szükség akár több hétig is. Egy-egy ilyen alom kezelése bizony 300 - 500.000 Ft állatorvosi költséget is generál.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Továbbra is rengeteg balesetes, sérült és beteg állatnak kérnek tőlünk segítséget az ország minden pontjáról. Ahogy hellyel és anyagiakkal bírjuk megpróbálunk minél több életet megmenteni. Nehezíti a helyzetünket, hogy az adományok radikális visszaesése mellett, az állatorvosi költségek, még a jelentős kedvezményeink mellett is megsokszorozódtak. Nem beszélve a gyógyszerek és gyógytápok áráról. `}</p>
    <p>{`2023-ban az állatorvosi költségeink, a gyógyszerek és a speciális tápok beszerzése 58,9 millió forint volt.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`A bajbajutott, megunt, felelőtlenül vállat, vagy tartott állatok száma folyamatosan nő. Miközben csökken az örökbefogadás, brutálisan nőnek a költségek, egyre több és több állatnak kellene segítség. Csapatokba verődött kóbor kutyák randalíroznak az ország sok pontján. Hiába született meg a Kóborállat Rendelet – aminek nagyon örülünk – még mindig sok gyepmester / gyepmesteri telep működik szabálytalanul, tartja iszonyú körülmények között a bekerülő kutyákat. Így ezekről a telepekről, vagy ezek elől a telepek elől az állatok megmentése tényleg élet vagy halál döntést jelent.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2023-ban 612 állat került a gondozásunkba, szinte mindannyian kutyák és cicák. A madárinfluenza tragédia miatt egyéb állatot az évben szinte nem fogadtunk. Nagyságrendileg ugyanennyit sikerült gazdásítanunk is az évben. Minden beszámoló legfájdalmasabb pontja azokról mesélni, akiknek elmaradt a csodája, akinek gazdi nélkül kellett átkelniük a szivárványhídon. Sajnos ekkora állatlétszám mellett a halál túl sokszor van jelen az életünkben. Rengeteg idős gazdasági állatunk van, a kutyusaink közül is szép számban vannak olyanok, akik bőven 10+ korúak. Ahogy a nehéz életkezdet sem. Cumistáink és beteg babáink közül is többektől kellett fájdalmas búcsút vennünk.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(21, 24)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Gazdaságilag már a 2022-es év is rengeteg nehézséget hozott és a fejlesztésekkel, az álmaink megvalósításával sajnos sok kompromisszumot kellett hoznunk. De azért mindig van néhány dolog amit sikerül megvalósítanunk, van amit mert nagyon régen vágyunk rá és van amit mert a törvény, a hatóságok erre köteleznek.`}</p>
    <p>{`A madárinfluenza tragédiáján túl helyrajzi elhelyezkedésünk miatt belekerültünk az afrikai sertéspestis (ASP) legszigorúbb megfigyelési körzetébe. Nálunk egyáltalán nem jelentkezett a betegség, de a járványügyi szabályok miatt komoly rendelkezéseket hozott a hatóság itt is. Egyrészről a disznóinkat teljesen el kell különítenünk minden más állatfajtól és az emberektől is. De nem akárhogy, dupla kerítéssel. Ezért totális átszervezéseket kellett csinálnunk. A. felső 1 hektáros területről a vaddisznókon kívül mindenki más (kecskék, ökör) leköltözött a az első legelőn kialakított karámba. Az új helyen kellett csinálni egy akkora beállót, amibe beférnek az új lakók. Ez a karám korábban a szamarak és összevérek helye volt. Ők arrébb költöztek, számukra teljesen új karámot és beállót kellett építeni. A fenti helyen pedig körbe ki kellett alakítani a dupla kerítést.  `}</p>
    <p>{`A gazdasági udvart is ketté kellett dupla kerítéssel választani. az istállók felöli részt kapták a házi disznók meg, a felső részt pedig a kecskék és a birkák. Nekik új istállót kellett építeni a korábbi kisgazdasági udvaron.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(30, 33)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Az eredeti kutyakennelek bizony már 30 évesek. Ezek állandó javításra, felújításra szorulnak. A kölyökszocializációs programhoz kialakítottunk 2 kennelt, melyek közvetlenül a gazdasági udvarról elérhetőek. Az alap átalakítást megcsináltuk, a végleges, igazi óvodai kialakításhoz még forrást kell teremtenünk.`}</p>
    <p>{`Természetesen azért továbbra is tele vagyunk tervekkel. A vizesblokk kialakításával kezdünk a célegyenesbe fordulni. Terveink szerint a 2024-es tavaszi rendezvényeinken már használatba is lehet venni őket. `}</p>
    <p>{`Az edukáció, a szemléletformálás évtizedek óta a szívügyünk. Egy új szuperhősös - állatmentős tanösvény kialakítása is a célegyenesben van.  Izgatottan várjuk, hogy fog tetszeni a hozzánk látogatóknak.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(33, 35)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Persze millió dolgot görgetünk magunk előtt: új galambdúc építése, a régi kennelek teljes felújítása, átalakítása, fix árnyékolók telepítése, a kutyás kórházrészleg teljes megújítása, egy új, modern kórház építésével, a macskás karantérészleg bővítése. Külső karanténunk felújítása, modernizálása is megoldásra vár, ahogy a felnőtt karantén is.`}</p>
    <p>{`Menhelysegítő programunk továbbra is teljes gőzzel működik. Több ezer kg kutya és cicatáppal, macskaalommal, gumikesztyűvel tudtuk egész évben folyamatosan segíteni a kisebb menhelyeket. Gyógyszerek, gyógyászati segédeszközök kerültek kiosztásra igény szerint.`}</p>
    <p>{`Vas vármegyei Szergényi kutyamenhelyünk is folyamatos teltházzal működik. Itt elsősorban olyan kutyáink vannak elhelyezve, akiknek túl sok a budapesti forgatag, a sok inger, akiknek nyugalomra és kitüntetett figyelemre van szüksége. Mostanra igazi rehabilitációs menhely funkciója lett. Hatalmas boldogság számunkra, hogy ott is kialakult egy csodás önkéntes csapat, akiknek hála minden hétvégén felpezsdült az élet, rendezvényeket tudunk tartani, boldog séták, adománygyűjtések zajlanak. És mindez természetesen magával hozta, hogy igencsak felélénkült arrafelé is az örökbefogadási kedv. Olyan kutyusaink - sok esetben bőven veterán korúak - kapnak új családot, akiknek azt gondoltuk eddig, hogy már esélyük sincs.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(24, 27)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`A mi életünkben szinte minden nap történik valami fontos, boldog, szívszaggató esemény. Ezekről próbálunk folyamatosan hírt adni a facebook és instagram oldalunkon, illetve elolvashatóak a weboldalunk is ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <p>{`2024`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.213 volt.`}</p>
    <p>{`Hálásan köszönjük támogatóinknak, önkénteseinknek a rengeteg segítséget. Nagyon nehéz időszakban vagyunk, országosan hatalmas gondokkal küzd az állatvédelem. A radikálisan visszaesett támogatások, a csökkenő örökbeadás számok mellett minden segítség szó szerint életmentő ebben az időszakban.`}</p>
    <p>{`Évről évre a biztos alaphátteret a személyi jövedelemadójukat felajánlók biztosítják nekünk, amiért szintén nagyon hálásak vagyunk. Arra kérünk mindenkit, hogy ne hagyják veszni ezt a támogatási lehetőséget és rendelkezzenek 2024. május 21-ig személyi jövedelemadójuk 1%-ról!`}</p>
    <p><strong parentName="p">{`MERT A CSODÁKAT EGYÜTT CSINÁLJUK!`}</strong></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(36, 39)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <SingleImage resources={[props.data.allJson.nodes[0].resources[39]]} nodes={props.data.allFile.nodes} mdxType="SingleImage" />
    <img src="/images/2024_1_szazalek_oldalborito.png" alt="Noé 1% Add vissza Te a Reményt!" />
    <p>{`Korábbi beszámolóink:`}</p>
    <p>
  <Link to="/erre-forditjuk-2022" mdxType="Link">Erre fordítjuk - 2022</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2021" mdxType="Link">Erre fordítjuk - 2021</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2020" mdxType="Link">Erre fordítjuk - 2020</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2019" mdxType="Link">Erre fordítjuk - 2019</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2018" mdxType="Link">Erre fordítjuk - 2018</Link>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      